class AjaxReplace {

    spinnerHtml = `
        <div class="spinner h-100">
            <div class="d-flex justify-content-center align-items-center h-100">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    `;

    constructor(target, url, data = {}, method = 'GET') {
        this.target = target;
        this.url = url;
        this.data = data;
        this.method = method;
    }


    replace() {
        let that = this;
        $(this.target).empty();
        $(this.target).append(this.spinnerHtml);

        const ajaxOptions = {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: this.url,
            method: this.method,
            data: this.data
        }

        $.ajax(ajaxOptions)
            .done(function (resp) {
                $(that.target).empty();
                $(that.target).append(resp);
            });
    }
}

export default AjaxReplace;
