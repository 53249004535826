/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./eliteadmin');
import SignaturePad from "signature_pad";

window.AjaxReplace = require('./ajax-replace').default;
window.usdFormatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
